import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import React, {useState} from 'react'
import {transformToKwNumberEvenIfNull, transformToKwNumber, validateNumericValue} from '../utils/common'
import {UpdateBpsParametersExtendedRequest} from '../../src/api/generated/model/update-bps-parameters-extended-request'
import {BpsParametersExtended} from '../../src/api/generated'
import {useBucketStore} from '../utils/BucketStoreContext'

type EditBpsDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: (request: UpdateBpsParametersExtendedRequest) => void
  onErrorDefaultMw: () => void
  bpsParamsExtended: BpsParametersExtended
}

export const EditBpsDialog: React.FC<EditBpsDialogProps> = ({
  open,
  onClose,
  onConfirm,
  bpsParamsExtended,
  onErrorDefaultMw,
}) => {
  const [maxReservedCapacityMwValue, setMaxReservedCapacityMwValue] = useState<number | undefined>(
    transformToKwNumberEvenIfNull(bpsParamsExtended.max_reserved_capacity_mw),
  )
  const [pDefaultMwValue, setPDefaultMwValue] = useState<number | undefined>(
    transformToKwNumber(bpsParamsExtended.p_default_mw),
  )
  const [tvsMwValue, setTvsMwValue] = useState<number | undefined>(transformToKwNumber(bpsParamsExtended.tvs_mw))
  const [tvsVarianceMwValue, setTvsVarianceMwValue] = useState<number | undefined>(
    transformToKwNumber(bpsParamsExtended.tvs_variance_mw),
  )
  const [ovsMwValue, setOvsMwValue] = useState<number | undefined>(transformToKwNumber(bpsParamsExtended.ovs_mw))

  const {data: userInfo} = useBucketStore('userInfo')

  const valuesAreValid = () => {
    return (
      pDefaultMwValue !== undefined &&
      pDefaultMwValue >= 0 &&
      tvsMwValue !== undefined &&
      tvsMwValue >= 0 &&
      tvsVarianceMwValue !== undefined &&
      tvsVarianceMwValue >= 0
    )
  }

  const valueIsValid = (value: number | undefined) => {
    return value !== undefined && value >= 0
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upraviť BPS [{bpsParamsExtended.p_inst_mw * 1000}]</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table size="small" sx={{padding: '20px 0'}}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>MRK [kW]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (kW)"
                    type="text"
                    value={maxReservedCapacityMwValue !== undefined ? maxReservedCapacityMwValue : null}
                    onChange={(e) => {
                      if (validateNumericValue(e.target.value)) {
                        e.target.value === ''
                          ? setMaxReservedCapacityMwValue(undefined)
                          : setMaxReservedCapacityMwValue(Number(e.target.value))
                      } else {
                        e.target.value = e.target.value.slice(0, -1) // remove the last invalid character
                      }
                    }}
                    disabled={!userInfo?.roles.includes('GROUP_MNG') && !userInfo?.roles.includes('GROUP_OPERATOR')}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>Bezpečný (default) výkon: [kW]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (kW)"
                    type="text"
                    value={pDefaultMwValue ?? 0}
                    onChange={(e) => {
                      if (validateNumericValue(e.target.value)) {
                        setPDefaultMwValue(Number(e.target.value))
                      }
                    }}
                    error={!valueIsValid(pDefaultMwValue)}
                    helperText={valueIsValid(pDefaultMwValue) ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>Tech. vlastná spotreba [kW]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (kW)"
                    type="text"
                    value={tvsMwValue ?? 0}
                    onChange={(e) => {
                      if (validateNumericValue(e.target.value)) {
                        setTvsMwValue(Number(e.target.value))
                      }
                    }}
                    error={!valueIsValid(tvsMwValue)}
                    helperText={valueIsValid(tvsMwValue) ? '' : 'Hodnota nesmie byť prázdna'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>TVS rozptyl [kW]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (kW)"
                    type="text"
                    value={tvsVarianceMwValue ?? 0}
                    onChange={(e) => {
                      if (validateNumericValue(e.target.value)) {
                        setTvsVarianceMwValue(Number(e.target.value))
                      }
                    }}
                    disabled={!userInfo?.roles.includes('GROUP_MNG') && !userInfo?.roles.includes('GROUP_OPERATOR')}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography>Ostatná vlastná spotreba [kW]</Typography>
                </TableCell>
                <TableCell>
                  <TextField
                    margin="normal"
                    label="Hodnota (kW)"
                    type="text"
                    value={ovsMwValue ?? 0}
                    onChange={(e) => {
                      if (validateNumericValue(e.target.value)) {
                        setOvsMwValue(Number(e.target.value))
                      } else {
                        setOvsMwValue(0)
                      }
                    }}
                    error={false}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Zrušiť
        </Button>
        <Button
          onClick={() => {
            if (pDefaultMwValue && pDefaultMwValue > bpsParamsExtended.p_inst_mw * 1000) {
              onErrorDefaultMw()
              return
            }

            if (valuesAreValid() && tvsMwValue && pDefaultMwValue) {
              const updateRequest: UpdateBpsParametersExtendedRequest = {
                ovs_mw: ovsMwValue ? ovsMwValue / 1000 : 0,
                p_default_mw: pDefaultMwValue / 1000,
                tvs_mw: tvsMwValue / 1000,
                max_reserved_capacity_mw: maxReservedCapacityMwValue ? maxReservedCapacityMwValue / 1000 : undefined,
                tvs_variance_mw: tvsVarianceMwValue ? tvsVarianceMwValue / 1000 : 0,
              }
              onConfirm(updateRequest)
            }
          }}
          variant="contained"
          disabled={!valuesAreValid()}
        >
          Potvrdiť
        </Button>
      </DialogActions>
    </Dialog>
  )
}
